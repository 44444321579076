/* You can add global styles to this file, and also import other style files */

:root {
  --Principal-Colors-Color-Principal: #e6007e;
  --Neutral-Colors-White: #fff;
  --Neutral-Colors-Color-50: #f9fafb;
  --Neutral-Colors-Color-200: #e7e9ee;
  --Neutral-Colors-Color-300: #cacfd8;
  --Neutral-Colors-Color-600: #3e4a5b;
  --Neutral-Colors-Color-400: #8d98aa;
  --kavak-light-color-text-disable: #8d98aa;
  --Secondary-Dark-Color: #54595e;
  --Stroke-light: #dee2e6;
  ---Gray-White: #fff;
}

html,
body {
  min-height: 100%;
  height: 100%;
  font-family: "DM Sans", sans-serif;
  font-feature-settings: "liga" off, "clig" off;
  background: var(--Neutral-Colors-Color-50);
}

.semi-title-badget {
  background: var(--Neutral-Colors-Color-200);
  border-radius: 10px;
  color: var(--Principal-Colors-Color-Principal);
  font-style: normal;
  font-weight: 700;
  padding: 0.5rem 2rem;
  width: fit-content;
}

.form-stepper {
  background: #fff;
  border-radius: 1.25rem;
  box-shadow: 0px 2px 6px 0px rgba(20, 20, 43, 0.06);
  color: var(--Neutral-Colors-Color-600);
  flex-shrink: 0;
  width: 26rem;
  margin-left: 15%;
}

.form-stepper-title {
  text-align: center;
  font-style: normal;
  line-height: 2.125rem;
  font-weight: 700;
  font-size: 1.5rem;
}

.form-stepper-select {
  padding: 11px;
  box-shadow: none;
  margin: 24px 0px;
}

.form-stepper-select:focus,
.form-stepper-select:active,
.form-stepper-input:focus,
.form-stepper-input:active {
  box-shadow: none;
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.form-stepper-input:focus,
.form-stepper-input:active {
  box-shadow: none;
  border: 1px solid var(--Principal-Colors-Color-Principal);
}

.floating-label-stepper-form {
  display: none;
}

.form-floating > .form-control:focus ~ label {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  color: var(--Neutral-Colors-Color-400);
}

.form-stepper-input {
  color: var(--Neutral-Colors-Color-600, #3e4a5b);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.form-stepper-check-label {
  color: var(--Neutral-Colors-Color-600);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}

.form-switch .form-check-input:focus {
  border-color: var(--Principal-Colors-Color-Principal);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}
.form-switch .form-check-input:checked {
  background-color: var(--Principal-Colors-Color-Principal);
  border-color: var(--Principal-Colors-Color-Principal);
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

.form-stepper-button {
  width: 100%;
  border-radius: 8px;
  background: var(--Principal-Colors-Color-Principal);
  border: 0;
  padding: 12px;
}

.form-stepper-button:hover,
.form-stepper-button:active,
.form-stepper-button:focus,
.form-stepper-button:disabled,
.form-stepper-button:focus-visible {
  background: var(--Principal-Colors-Color-Principal);
  background-color: var(--Principal-Colors-Color-Principal);
  border-color: var(--Principal-Colors-Color-Principal);
  box-shadow: none;
}

.btn:first-child:hover,
.btn:first-child:active,
.btn:first-child:focus,
.btn:first-child:disabled,
.btn:first-child:focus-visible {
  background: var(--Principal-Colors-Color-Principal);
  background-color: var(--Principal-Colors-Color-Principal);
  border-color: var(--Principal-Colors-Color-Principal);
  box-shadow: none;
}

.form-stepper-extra {
  font-style: normal;
  font-size: 0.65rem;
  font-weight: 400;
  line-height: 1rem;
  margin-left: 10px;
  padding-bottom: 11px;
}

.form-select-search {
  padding: 11px 25px 11px 11px;
}

.form-select-search:hover,
.form-select-search:active,
.form-select-search:focus {
  box-shadow: none;
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.ng-select-container {
  padding: 23px 0px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.ng-input > input {
  position: relative;
  top: 5px;
}

.ng-has-value.ng-select-container:focus-within {
  border: var(--bs-border-width) solid var(--bs-border-color);
  box-shadow: none;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.ng-select-container.ng-has-value:focus {
  border: var(--bs-border-width) solid var(--Principal-Colors-Color-Principal);
  box-shadow: none;
}

.ng-select.ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  box-shadow: none;
}

ng-select.ng-invalid.ng-touched .ng-select-container,
.is-invalid {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 1.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.hiring-title {
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.125rem;
}

.hiring-text {
  text-align: center;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}

.bottom-text {
  color: var(--Principal-Colors-Color-Principal);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
}

.bottom-text:hover {
  cursor: pointer;
}

.hiring-container {
  align-items: center;
  background: var(--Neutral-Colors-White);
  border-radius: 0.5rem;
  border: 1px solid var(--Neutral-Colors-Color-200);
  color: var(--Neutral-Colors-Color-600);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  gap: 1rem;
  height: 8.625rem;
  justify-content: center;
  line-height: 1.75rem;
}

@media (max-width: 1400px) {
  .semi-title-badget {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 768px) {
  .semi-title-badget {
    font-size: 16px;
    line-height: 18px;
  }

  .form-stepper {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 576px) {
  .semi-title-badget {
    font-size: 14px;
    line-height: 16px;
  }

  .form-stepper {
    box-shadow: none;
    width: 100%;
    gap: 0;
    background: transparent;
    margin-left: auto;
    margin-right: auto;
  }

  .form-stepper-select,
  .form-stepper-button,
  .form-stepper-input {
    font-size: 1rem;
  }
}
